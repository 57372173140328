@import url('https://fonts.googleapis.com/css?family=Righteous|Special+Elite&display=swap');
@import url("./materialize-css/dist/css/materialize.css");
:root {
  --primary-font: "Special Elite", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --header-font: "Righteous", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --entrance-timing: 0.3s;
  --active-color: #e81212;
}

body {
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.linkText {
  transition: all .5s;
}

.linkText :hover {
  color: var(--active-color) !important;
  text-shadow: 0 0 0.15em red;
  user-select: none;
  white-space: nowrap;
  filter: blur(0.007em);
  transition: all .5s;
}
button.btn {
  font-family: var(--primary-font);
}
.shake-blue {
  position: relative;
  text-transform: uppercase;
  text-shadow: 0 0 0.15em #67bed4;
  user-select: none;
  white-space: nowrap;
  filter: blur(0.007em);
  animation: shake 2.5s linear forwards;
  animation-iteration-count: infinite;
}
.shake-red {
  position: relative;
  text-transform: uppercase;
  text-shadow: 0 0 0.15em red;
  user-select: none;
  white-space: nowrap;
  filter: blur(0.007em);
  animation: shake 3.5s linear forwards;
  animation-iteration-count: infinite;
}

/* Animations */

.certifications_slide-appear-active {
  animation-name: slideInRight;
  animation-duration: var(--entrance-timing);
}

.Projects_slide-appear-active {
  animation-name: slideInLeft;
  animation-duration: var(--entrance-timing);
}

.home_slide-appear-active {
  animation-name: slideInDown;
  animation-duration: var(--entrance-timing);
}

.certifications_slide-exit-active {
  animation-name: slideOutRight;
  animation-duration: var(--entrance-timing);
}

.Projects_slide-exit-active {
  animation-name: slideOutLeft;
  animation-duration: var(--entrance-timing);
}

.home_slide-exit-active {
  animation-name: slideOutDown;
  animation-duration: var(--entrance-timing);
}

@keyframes shake {
  5%,
  15%,
  25%,
  35%,
  55%,
  65%,
  75%,
  95% {
    filter: blur(0.018em);
    transform: translateY(0.018em) rotate(0deg);
  }

  10%,
  30%,
  40%,
  50%,
  70%,
  80%,
  90% {
    filter: blur(0.01em);
    transform: translateY(-0.018em) rotate(0deg);
  }

  20%,
  60% {
    filter: blur(0.03em);
    transform: translate(-0.018em, 0.018em) rotate(0deg);
  }

  45%,
  85% {
    filter: blur(0.03em);
    transform: translate(0.018em, -0.018em) rotate(0deg);
  }

  100% {
    filter: blur(0.007em);
    transform: translate(0) rotate(-0.5deg);
  }
}

/* Breakpoints  */

/* <= 480 */

#app-wrapper {
  --padding-xs: "0px 16px" !important;
  --padding-sm: "0px 32px" !important;
  --padding-md: "0px 64px" !important;
  --padding-lg: "0px 128px" !important;
  --padding-xl: "0px 256px" !important;
  --padding-xx: "0px 512px" !important;
}

@media all and (max-width: 480px) {
  #app-wrapper {
    padding: var(--padding-xs);
  }
}

/* 480 - 736 */

@media all and (min-width: 481px) and (max-width: 736px) {
  #app-wrapper {
    padding: var(--padding-sm);
  }
}

/* 737 - 980 */

@media all and (min-width: 737px) and (max-width: 980px) {
  #app-wrapper {
    padding: var(--padding-md);
  }
}

/* 981 - 1280 */

@media all and (min-width: 981px) and (max-width: 1280px) {
  #app-wrapper {
    padding: var(--padding-lg);
  }
}

/* 1281 - 1690 */

@media all and (min-width: 1281px) and (max-width: 1690px) {
  #app-wrapper {
    padding: var(--padding-xl);
  }
}

/* > 1690 */

@media all and (min-width: 1691px) {
  #app-wrapper {
    padding: var(--padding-xx);
  }
}
